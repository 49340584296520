import React, { Component } from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";

import EmployeeTestimonialSlider from "../components/employeeTestimonialSlider";
import Swiper, { EffectCoverflow, Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
import NewsSlider from "../components/index-news-slider-component";
import PopupForm from "../components/popupJob";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import jobDesc from "../images/job-description.svg"
import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';

// Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);
// var accItem;
class Career extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            time: 90000000,
            popData: undefined,
            emailSubject: undefined,
            emailContent: undefined,
            HomeNews : this.props.data.allWordpressWpNews.edges,
            time: 90000000
        }
    }

    componentDidMount() {

        
       
  
          
          
        var swiper = new Swiper('.swiper-news', {
          slidesPerView: 1,
          spaceBetween: 20,
          slidesPerGroup: 1,
          loop: true,
          loopFillGroupWithBlank: false,
         
          autoplay: { 
            delay: 7000, 
            disableOnInteraction: false 
          },
          pagination: {
            el: '.swiper-pagination-news',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-next-news',
            prevEl: '.swiper-prev-news',
          },
          breakpoints: { 
            1200: { slidesPerGroup: 3, slidesPerView: 3 }, 
            992: { slidesPerGroup: 2, slidesPerView: 2 }, 
            768: { slidesPerGroup: 1, slidesPerView: 1 }
        }
        });
  
       
         
      }

      
    // handlePopupWindow = () => {
    //     this.setState({ time: Math.random() })
    // }

    // componentDidMount() {

    //     accItem = document.getElementsByClassName('accordionItem');
    //     let accHD = document.getElementsByClassName('accordionItemHeading');
    //     for (let i = 0; i < accHD.length; i++) {
    //         accHD[i].addEventListener('click', this.toggleItem, false);
    //     }
    // }
    // accorfn = () => {

    //     accItem = document.getElementsByClassName('accordionItem');
    //     let accHD = document.getElementsByClassName('accordionItemHeading');
    //     for (let i = 0; i < accHD.length; i++) {
    //         accHD[i].addEventListener('click', this.toggleItem, false);
    //     }

    // }
    // toggleItem() {

    //     var itemClass = this.parentNode.className;
    //     for (let i = 0; i < accItem.length; i++) {
    //         accItem[i].className = 'accordionItem close';
    //     }
    //     if (itemClass == 'accordionItem close') {
    //         this.parentNode.className = 'accordionItem open';
    //     }
    // }

    handlePopupWindow = (data, subject, content) => {
        this.setState({ time: Math.random() , popData: data, emailSubject: subject, emailContent: content})
    }

    // for (var i = 0; i < job_post.length; i++) {
        
    // }



    render() {


        const post = this.props.data.allWordpressPage.nodes[0];
        const metatitle = post.acf.meta_title ;
        const metadescription = post.acf.meta_description;
        const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = post.acf.canonical_url; 

        const job_post = this.props.data.allWordpressWpJobPortal.edges;
        const job_data = this.props.data.allWordpressPage.nodes[0].acf.job_section.job_post_data;

        const HomeNews = this.state.HomeNews;
        
        const combine_job_item = [];       
        for (var i = 0; i < job_post.length; i++) { 
            combine_job_item[job_post[i].node.wordpress_id] = job_post[i].node;
        }
  
  //console.log("final=>"+combine_job_item);
        /*for (var i = 0; i < job_post.length; i++) {
            for (var j = 0; j < job_data.length; j++) {
                if(job_post.wordpress_id == job_data.wordpress_id) { 
                    console.log( job_post.wordpress_id.'===='.job_data.wordpress_id);
                }
            }
        }*/
        return (

            <div>
                <PopupForm time={this.state.time} popDataSet={this.state.popData} emailSubjectSet={this.state.emailSubject} emailContentSet={this.state.emailContent}/>
                <Layout>
                <Helmet>
                <title>{metatitle}</title>
			    <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          {(() => {if(post.acf.canonical_url != '' && post.acf.canonical_url != null) {return (<link rel="canonical" href={canonical_url} /> )} })()} 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          <link rel="icon" href="https://assets.theworldgrad.com//gatsby-assets/xtra/twg_favicon_ymgrbw.png" />


                </Helmet>

                <div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item active">{post.title}</li>
										</ol>
				</div>
				</div>



                    <section class="career-banner-sec" id="career_banner_sec">
                        <div class="career-banner-inner">
                            <div class="career-banner-img-wrap">
                                    <img src={post.acf.intro_section.source_url} alt="Career Banner"/>
                            </div>
                        </div>
                    </section>
                    {/* <section class="benifits-section py-5" id="benifits_section">
                        <div class="container">
                            <h3 class="benifits-main-title text-center mb-5">{post.acf.benefit_section.benefit_section_label}</h3>
                            <div class="row">
                            {
                                post.acf.benefit_section.benefits_data.map((item,index)=> {
                                    return <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="benifits-box">
                                            <div class="benifit-icon-wrap">
                                                <img src={item.benefit_icon.source_url} alt="benifit icon"/>
                                            </div>
                                            <div class="benifits-contant-wrap">
                                                <h4>{item.benefit_title}</h4>
                                                <p>{item.benefit_description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            </div>
                        </div>
                    </section> */}
                    <section className="american-dream-card-section py-5" id="benifits_section">
                        <div className="container">
                            <h1 class="benifits-main-title text-center mb-2 h3">{post.acf.benefit_section.benefit_section_label}</h1>
                            <p class="mb-5 text-center">{post.acf.benefit_section.benefit_section_content}</p>
                            <div className="row">
                            {
                                post.acf.benefit_section.benefits_data.map((item,index)=> {
                                    return <div className="col-md-6 mb-4">
                                    <div className="card american-dream-main-card custom-card-main-wrap">
                                        <div className="card-body">
                                            <div className="row align-items-center custom-card-wrap">
                                                <div className="card-icon-wrap">
                                                    <img src={item.benefit_icon.source_url} className="unismarted-card-logo-1" alt="Icon" />
                                                </div>
                                                <div className="card-content-wrap">
                                                    <h5>{item.benefit_title}</h5>
                                                    <p classname="american-dream-card-text">{item.benefit_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                })
                            }
                                {/* <div className="col-md-6 mb-4">
                                    <div className="card american-dream-main-card">
                                        <div className="card-body">
                                        <div className="row align-items-center">
                                                <div className="col-3 d-flex justify-content-center">
                                                    <img src={unismarterImage2} className="unismarted-card-logo-1" alt="Icon"/>
                                                </div>
                                                <div className="col-9 px-md-5">
                                                    <h5> Flexibility All The Way </h5>
                                                    <p classname="american-dream-card-text">
                                                        Attend classes by international faculty during Indian time zones and get individual tutor support available 7 days a week
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <div className="card american-dream-main-card">
                                        <div className="card-body">
                                        <div className="row align-items-center">
                                                <div className="col-3 d-flex justify-content-center">
                                                    <img src={unismarterImage3} className="unismarted-card-logo-1" alt="Icon"/>
                                                </div>
                                                <div className="col-9 px-md-5">
                                                    <h5> A Rupee Saved Is A Rupee Earned </h5>
                                                    <p classname="american-dream-card-text">
                                                        Complete the first semester (4 modules) at roughly 30% the on-campus cost with UniSmarter. Moreover, save 6 months of living expenses by starting online 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <div className="card american-dream-main-card">
                                        <div className="card-body">
                                        <div className="row align-items-center">
                                                <div className="col-3 d-flex justify-content-center">
                                                    <img src={unismarterImage4} className="unismarted-card-logo-1" alt="Icon"/>
                                                </div>
                                                <div className="col-9 px-md-5">
                                                    <h5> Save Time And Trouble </h5>
                                                    <p classname="american-dream-card-text">
                                                        No IELTS or Visa required to start the program. Start right away while you prepare to move overseas
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>
                    
                    <section class="awards-section py-5" id="job_section">
                        <div class="container">
                            <h3 class="awards-main-title text-center mb-5">{post.acf.job_section.job_section_label}</h3>
                            <div class="row">
                                <div class="col-12 table-wrap">
                                    <div className="job-openings-list">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{width: "20%"}}>Job title</th>
                                                    <th style={{width: "15%"}}>Job Description</th>
                                                    <th className="d-none d-lg-table-cell" style={{width: "15%"}}>Department</th>
                                                    <th className="d-none d-lg-table-cell" style={{width: "20%"}}>Location</th>
                                                    <th className="d-none d-lg-table-cell" style={{width: "20%"}}>Employment type</th>
                                                    <th style={{width: "10%"}}>Apply</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                post.acf.job_section.job_post_data.map((item,index)=> {
                                                    return <tr>
                                                        <td>
                                                            <a id="job_title" href={"mailto:" + combine_job_item[item.wordpress_id].acf.hr_email_address + "?subject=" +item.post_title } >{item.post_title}</a>
                                                        </td>
                                                        <td className="career-jd-link">
                                                            {combine_job_item[item.wordpress_id].acf.job_description === null ?
                                                                (<span>-</span>):
                                                                (<a target="blank" href={combine_job_item[item.wordpress_id].acf.job_description.url.source_url} title="Job Description"><img src={jobDesc} alt="Job Description" /></a>)
                                                            }
                                                        </td>
                                                         <td className="d-none d-lg-table-cell">
                                                            {combine_job_item[item.wordpress_id].acf.department}
                                                        </td>
                                                        <td className="d-none d-lg-table-cell">
                                                            {combine_job_item[item.wordpress_id].acf.locaion}
                                                        </td>
                                                        <td className="d-none d-lg-table-cell">
                                                            {combine_job_item[item.wordpress_id].acf.employment_type}
                                                        </td>
                                                        <td>
                                                            <a id="job_title" className="btn btn-primary" href={"mailto:" + combine_job_item[item.wordpress_id].acf.hr_email_address + "?subject=" +item.post_title } >Apply</a>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="employee-slider-section py-5" id="employee_slider_section" style={{backgroundColor: "#fff"}}>
                        <div className="container">
                            <h3 class="awards-main-title text-center mb-5">{post.acf.employee_section.employee_label}</h3>
                            <div className="row">
                                <div className="col-12">
                                    <EmployeeTestimonialSlider EmployeeTestmonials={post.acf.employee_section.employee_data} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="gallery-section py-5" id="gallery_section">
                        <div className="container">
                            <h3 class="gallery-main-title text-center mb-5">{post.acf.gallery_section.gallery_label}</h3>
                            <div className="row">
                                <div className="col-12">
                                    <ResponsiveMasonry
                                            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}} gutter="10px" class="gallery-item"
                                    >
                                        <Masonry>
                                            {   
                                                post.acf.gallery_section.gallery_items.map((item,index)=> {
                                                    return <img src={item.source_url} alt="Gallery Image"/>
                                                })
                                            }
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </div>
                            </div>
                        </div>
                    </section>



    <div class="newStyle">
                    <section class="py-5 newStyle bg-dark-gray" >
                        <div class="container">
                            <h3 class="awards-main-title text-center mb-5">{post.acf.award_section.label}</h3>
                            <div class="row">
                            <div class="col-12 text-center position-relative">
                                   <div class="swiper-container swiper-news text-center university" >
							
                        <NewsSlider news_section_title={post.acf.award_section.label} HomeNews={HomeNews} />
                        </div>
                        <div class="swiper-pagination swiper-pagination-news"></div>
                        

                        <div class="swiper-button-next swiper-next-news swiper-button-next-nav  rounded-circle light slider-navigation-style-07 box-shadow-double-large"><img src={ArrowRightSolidIcon} alt="->" className="left-icon newicon newicon-black left2pxtop0px" /></div>
		                <div class="swiper-button-prev swiper-prev-news swiper-button-prev-nav rounded-circle light slider-navigation-style-07 box-shadow-double-large"><img src={ArrowLeftSolidIcon} alt="<-" className="left-icon newicon newicon-black left2pxtop0px" /></div>
                    
                        </div>
                            </div>
                        </div>
                        <br/><br/>
                    </section>
                    </div>
                </Layout>


                

            </div>


           


        )


    }
}
export default Career;
export const CareerQuery = graphql`
query CareerQuery {
    allWordpressPage(filter: {wordpress_id: {in: 1965}}) {
      nodes {
        title
        wordpress_id
        acf {
           
          intro_section {
            source_url
          }
          meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema

          benefit_section {
            benefit_section_label
            benefit_section_content
            benefits_data {
              benefit_title
              benefit_icon {
                source_url
              }
              benefit_description
            }
          }
          award_section {
            label
            award_data {
                award_link
                award_logo {
                    source_url
                }
            }
          }
          employee_section {
            employee_label
            employee_data {
              employee_name
              employee_designation
              employee_profile_picture {
                source_url
              }
              employee_details
            }
          }
          job_section {
            job_section_label
            job_post_data {
              post_title
              wordpress_id
              post_date(formatString: "YYYY MM DD")
            }
          }
          gallery_section {
            gallery_label
            gallery_items {
              source_url 
            }
          }
        }
      }
    }
    allWordpressWpJobPortal {
      edges {
        node {
          wordpress_id
          title
          acf {
            locaion
            hr_email_address
            department
            employment_type 
            job_description {
                link
                title
                url {
                    source_url
                }
            }
          } 
          content
        }
      }
    }
    allWordpressWpNews(sort: {order: ASC, fields: id}) {
        edges {
          node {
            id
            wordpress_id
            type
            title
            status
            slug
            menu_order
            content
            featured_media {
              source_url
              id
              type
              wordpress_id
              title
            }
            acf {
              news_date
              news_content_link
              news_content
            }
          }
        }
      }
  }     
`
