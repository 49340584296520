import React from 'react'
import Slider from 'react-slick'
import EmployeeTestimonialCard from './employeeTestimonialCard'
import { graphql, useStaticQuery } from 'gatsby'


const EmployeeTestimonialSlider = ({props, EmployeeTestmonials}) => {

  //console.log("Testeee", EmployeeTestmonials.length);
  // console.log("EmployeeTestmonials==>"+JSON.stringify(EmployeeTestmonials, null, 2));

  /* const query = useStaticQuery(graphql`
        {
            dinesh: file(relativePath: {eq: "dinesh.jpg"}) {
                base
                childImageSharp {
                  fixed (width: 150, height: 150){
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
            }
            antra: file(relativePath: {eq: "antra.jpg"}) {
                base
                childImageSharp {
                  fixed (width: 150, height: 150){
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
            }
            jose: file(relativePath: {eq: "jose.jpg"}) {
                base
                childImageSharp {
                  fixed (width: 150, height: 150){
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
            }
            kashish: file(relativePath: {eq: "kashish.jpg"}) {
                base
                childImageSharp {
                  fixed (width: 150, height: 150){
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
            }
            atharva: file(relativePath: {eq: "atharva.jpg"}) {
                base
                childImageSharp {
                  fixed (width: 150, height: 150){
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
            }
            rahul: file(relativePath: {eq: "rahul.jpg"}) {
                base
                childImageSharp {
                  fixed (width: 150, height: 150){
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
            }
            ukFlagAni: file(relativePath: {eq: "uk-animated.jpg"}) {
                base
                childImageSharp {
                  fixed (height: 50) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
            }
            ausFlagAni: file(relativePath: {eq: "australia-animated.jpg"}) {
                base
                childImageSharp {
                  fixed (height: 50) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
            }
        }
    `)*/

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }


    return (

      
        <div className="mb-5">

            <Slider {...settings} >


            {EmployeeTestmonials && EmployeeTestmonials.length && EmployeeTestmonials.map((testmonial) => (

                <div>
                    <EmployeeTestimonialCard
                        studentName={testmonial.employee_name}
                        place={testmonial.employee_designation}
                        studentTestimonial={testmonial.employee_details}
                        imgUrl={testmonial.employee_profile_picture?.source_url || "https://assets.theworldgrad.com//gatsby-assets/xtra/Rahul-1-1-1.jpg"}
                        //imgUrl="https://assets.theworldgrad.com//gatsby-assets/xtra/Rahul-1-1-1.jpg"
                        // imgUrl={testmonial.employee_profile_picture.source_url}
                        // // uniLogo="https://assets.theworldgrad.com//gatsby-assets/xtra/australia-g86573a1f2_640.jpg"
                        // uniLogo={testmonial.node.acf.country_flag.source_url}
                        // batch={testmonial.node.acf.batch_of_year}
                    />
                </div>
            ))}

            </Slider>
        </div>
    )
}

export default EmployeeTestimonialSlider